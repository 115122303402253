import { MenuIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import Link from "next/link";
import { useState } from "react";
import MmfLogo from "assets/svg/MmfLogo";
import { SideMenu } from "./SideMenu";
import useLogout from "hooks/useLogout";
import HeadlessModal from "../HeadlessModal";
import { LoggedButtons } from "./LoggedButtons";

const routes = [
  {
    name: "Shapes",
    href: "/create/shapes",
  },
  { name: "Fragrances", href: "/scents" },
  { name: "Guidelines", href: "/guidelines" },
  {
    name: "Pricing",
    href: "/create/shapes",
  },
  { name: "FAQ", href: "/faq" },
];

export const HeaderV2 = ({ isLogged }) => {
  const router = useRouter();
  const [open, setOpen] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const logout = useLogout();

  const onLogin = () => {
    if (["/create/[orderId]/[designId]/lasso"].includes(router.pathname))
      setShowConfirmationModal("/auth/signin");
    else router.push("/auth/signin");
  };

  return (
    <>
      <div className="fixed bg-[#6e818d] w-full z-[999]">
        <nav className="max-w-[80rem] z-[1000] flex justify-between items-center mx-auto h-[74px] py-2 px-4">
          <button
            aria-label="Toggle navigation"
            onClick={() => setOpen(!open)}
            className="flex p-2 w-fit bg-slate-500 text-gray-400 rounded-md border-2 border-transparent active:border-white lg:hidden"
          >
            <span className="sr-only">Toggle navigation</span>
            <MenuIcon className="cursor-pointer w-6 h-6" />
          </button>

          <Link href="/" className="text-gray-200">
            <a
              className="lg:hidden flex items-center px-2"
              onClick={() => setOpen(false)}
              aria-label="Make My Freshener Logo"
            >
              <MmfLogo alt={`Make My Freshener`} width={129} height={65} />
            </a>
          </Link>

          <Link href="/" className="text-gray-200 ">
            <a className="relative left-8 flex items-center cursor-pointer lg:w-[189px] lg:h-[110px] lg:-mt-3 w-full h-[92px] -mt-4 top-5 le">
              <span className="sr-only">Homepage</span>
              <span className="hidden lg:block">
                <MmfLogo alt={`Make My Freshener`} width={170} height={100} />
              </span>
            </a>
          </Link>

          <div className="hidden lg:flex space-x-6 items-center">
            {routes.map((route) => (
              <div key={route.name}>
                <Link href={route.href} prefetch={false}>
                  <a className="uppercase text-gray-50 hover:text-gray-400 font-bold transition-colors duration-100 cursor-pointer text-lg">
                    {route.name}
                  </a>
                </Link>
              </div>
            ))}
            <div>
              <button
                className="uppercase text-gray-50 hover:text-gray-400 font-bold transition-colors duration-100 text-lg"
                onClick={() => {
                  router.push(`/cart`);
                }}
              >
                Cart
              </button>
            </div>
            <div>
              <Link href="/create/shapes">
                <a className="whitespace-nowrap blue-button font-bold text-lg px-2 pt-1.5 pb-1 rounded">
                  DESIGN NOW
                </a>
              </Link>
            </div>
            <div className="relative hidden lg:flex ml-6 items-center gap-6 w-[88px]">
              <LoggedButtons
                logout={logout}
                onLogin={onLogin}
                isLogged={isLogged}
              />
            </div>
          </div>

          <Link href="/create/shapes">
            <a className="lg:hidden blue-button ml-auto font-bold px-2 pt-1.5 pb-1 rounded whitespace-nowrap">
              DESIGN NOW
            </a>
          </Link>
        </nav>
      </div>
      <SideMenu
        onLogin={onLogin}
        open={open}
        setOpen={setOpen}
        routes={routes}
        isLogged={isLogged}
      />
      <HeadlessModal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        variant="danger"
        title="Are you sure?"
        text="You can sign in after creating your custom freshener! If you leave the page now, you might need to restart the process."
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={() => {
          router.push(showConfirmationModal);
          setShowConfirmationModal(false);
        }}
      />
    </>
  );
};
