import { XIcon } from "@heroicons/react/outline";
import { ShoppingCartIcon, UserCircleIcon } from "@heroicons/react/solid";
import useLogout from "hooks/useLogout";
import Link from "next/link";
import { useRouter } from "next/router";

export const SideMenu = ({ routes, open, setOpen, isLogged, onLogin }) => {
  const router = useRouter();
  const logout = useLogout();

  return (
    <>
      <aside
        className={`fixed lg:hidden w-[50%] max-w-[280px] z-[1001] h-screen ${
          open ? "left-0" : "left-[-100%]"
        } duration-500`}
      >
        <div className="flex flex-col absolute z-[101] min-h-screen w-full bg-slate-500">
          <button
            className="flex p-1 w-fit bg-slate-500 text-gray-400 rounded-md active:text-white m-4"
            onClick={() => setOpen(!open)}
          >
            <XIcon className="cursor-pointer w-10 h-10" />
          </button>

          <div className="grid">
            <div className="grid gap-6">
              <div className="grid gap-2 px-4 bg-slate-600">
                {isLogged && (
                  <Link href="/profile" prefetch={false}>
                    <a
                      onClick={() => setOpen(false)}
                      className="text-gray-200 hover:text-gray-400 font-medium cursor-pointer flex gap-2 py-3"
                    >
                      <UserCircleIcon className="w-6 h-6" />
                      My Account
                    </a>
                  </Link>
                )}
                <button
                  className="text-gray-200 hover:text-gray-400 font-medium cursor-pointer flex gap-2 py-3 w-full"
                  onClick={() => {
                    router.push(`/cart`);
                    setOpen(false);
                  }}
                >
                  <ShoppingCartIcon className="w-6 h-6" />
                  My Cart
                </button>
              </div>
              <div className="px-4 grid gap-8">
                {routes.map((route) => (
                  <Link href={route.href} key={route.name} prefetch={false}>
                    <a
                      onClick={() => setOpen(false)}
                      className="text-gray-200 hover:text-gray-400 font-medium cursor-pointer w-full"
                    >
                      {route.name}
                    </a>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="p-4 mt-auto">
            {isLogged ? (
              <button
                type="button"
                onClick={() => {
                  logout();
                  setOpen(false);
                }}
                className="cursor-pointer w-full px-4 py-2 border border-transparent rounded-md font-medium text-gray-800 bg-gray-200 hover:bg-gray-100"
              >
                Sign out
              </button>
            ) : (
              <button
                type="button"
                onClick={() => {
                  onLogin();
                  setOpen(false);
                }}
                className="cursor-pointer w-full px-4 py-2 border border-transparent rounded-md font-medium text-gray-800 bg-gray-200 hover:bg-gray-100"
              >
                Sign In
              </button>
            )}
          </div>
        </div>
      </aside>
      {/* backdrop */}
      <div
        className={`fixed z-[1000] min-h-screen w-full right-0 lg:hidden ${
          open ? "block" : "hidden"
        }`}
        onClick={() => setOpen(!open)}
      />
    </>
  );
};
