import Footer from "components/common/Footer";

import { useSession } from "next-auth/react";
import { HeaderV2 } from "./HeaderV2/HeaderV2";

const Layout = ({ children, hideHeaderOnMobile }) => {
  const { status } = useSession();
  const isLogged = status === "authenticated";

  return (
    <>
      <div className={hideHeaderOnMobile ? "hidden lg:block" : ""}>
        <HeaderV2 isLogged={isLogged} />
      </div>
      <main
        className={`flex-1 h-screen ${
          hideHeaderOnMobile ? "lg:pt-[74px]" : "pt-[74px]"
        }`}
      >
        {children}
        <Footer />
      </main>
    </>
  );
};

export default Layout;
